import React from 'react';
import Nav from '../Nav.js';
import Footer from '../Footer.js'




function ExtremeOwnership(){

    return (
        <body>
        <Nav /><br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="container">
           <div class="row">
            <div class="col-1"></div>
              <div class="col-md-6">
              <div class=""><img style={{width:250}}src="blogimages/blog1.jpg" alt=""/></div>
              </div>
              <div class="col-md-4">
                 <div class="about_text_main">
                    <h1 class="blog_title">Book Review: Extreme Ownership</h1>
                    <h3 class="blog_author">By: Ryan Pinkney</h3><b></b>
                    <span class="blog_date">5/24/2023</span>
                 </div>
              </div>
              <div class="col-1"></div>
           </div>
            <hr></hr>
           {/* This section is for showing out the blog posts */}
           <div class="row">
                <div class="col">
                    <p class="blog_text">This book will leave you wanting more. 
                    In their novel “Extreme Ownership: How U.S. Navy SEALS Lead and Win”, Jocko Willink and Leif Babin 
                    recount their first-hand leadership experiences as Navy Seal during the conflict in Iraq following 
                    the terrorist attacks of September 11, 2001. 
                    </p>
                    <p class="blog_text">In these high-pressure situations on the battlefield, a leader’s 
                    decision often determines the outcome of the whole mission. A bad decision can result in danger or loss of life. 
                    Willink and Babin use these high-pressure, high-stake situations to teach business leaders how to succeed not only 
                    in industry but in life. After their retirement from the Navy, Willink and Babin started a consulting firm that worked 
                    with companies to improve their productivity in a wide variety of areas. 
                    </p> 
                    <p class="blog_text">“Extreme Ownership: How U.S. Navy SEALs Lead and Win” 
                    presents a narrative and analysis of both their war stories and consulting experiences. The novel is structured by presenting 
                    twelve principles, each of which includes a war story and a consulting experience. The stories are both educational and entertaining.</p>
                    <p class="blog_text">This novel is not your typical book about leadership. Combining combat stories and leadership training, “Extreme Ownership: How U.S. 
                     Navy SEALS Lead and Win” is impactful. The war stories are engaging, intense, and fast-paced, and the consulting experiences are 
                     thought-provoking, character-building, and fascinating. Together, they create a story that captures the readers’ attention.</p> 
                     <p class="blog_text">While other leadership books feel like long, dry college lectures, Willink and Babin take their readers on an emotional journey of fighting on 
                     the front lines, saving lives, and protecting our country. In the most respectful manner, they narrate the pressures, emotions, 
                     and fears of fighting in some of the most dangerous areas of Iraq and provide the reader with insight into the intensity of conducting 
                     rigorous and dangerous missions. Also, they tackle some of the most taboo topics in business and instruct on how to resolve the most 
                     daunting obstacles. For those interested in both war stories and business tricks, “Extreme Ownership: How U.S. Navy SEALs Lead and Win” 
                     is a thrilling novel that both entertains and teaches.</p>
                </div>
           </div>
           <br></br>
           <hr></hr><br></br>
           {/* End Section */}
           
        </div>
        <Footer />
    </body>

    );
}

export default ExtremeOwnership;