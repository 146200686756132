import React from 'react';



function SurveyForm(){

    return (
            <div class="services_section_2 layout_padding">
                <div class="container">
                    <div class="row">
                    <h1 class="form-bigtitle">Tell us about you...</h1>
                    </div><br></br>
                    <div class="row">
                        <div class="col-5">
                        <h1 class="form-title">What is your industry?</h1>
                            
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Technology</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Retail</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Private Equity</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Mining</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Logistics</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Online</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Public</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Education</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Financial Services</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Mobile Application</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Health Care</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Chemicals</a></label>              
                            
                        </div>
                        <div class="col">
                        <h1 class="form-title">How can we help?</h1>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Change Management</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Data Analytics</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Cost Reduction</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Customer Aquisition</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Organization</a></label> 
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Operations</a></label>
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Salesforce</a></label>  
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Web Launch</a></label>  
                        <label class="btn btn-outline-primary mr-2" for="btn-check-outlined"><a href="contact" class="industry-text">Revenue Growth</a></label>    
                        </div>
                    </div>
                </div>
                <br></br><br></br>   <br></br><br></br>
            </div>


    );
}

export default SurveyForm;