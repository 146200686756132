import React from 'react';




function Footer(){

    return (

        <div class="footer_section layout_padding">
        <div class="container">
           <div class="row">
              <div class="col-md-12">
                 {/* <div class="location_text">
                    <ul>
                       <li>
                          <a href="/"> <i class="fa fa-map-marker" aria-hidden="true"></i></a>
                       </li>
                       <li>
                          <a href="/"> <i class="fa fa-phone" aria-hidden="true"></i></a>
                       </li>
                       <li>
                          <a href="/"> <i class="fa fa-envelope" aria-hidden="true"></i></a>
                       </li>
                    </ul>
                 </div> */}
              </div>
              {/* <div class="footer_logo">
                 <a href="indexz.html">
                    <img src="images/logo.png" alt="" /> </a>
              </div> */}
              <div class="social_icon">
              <ul>
              {/* <li><a href="/"> <i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="/"> <i class="fa fa-twitter" aria-hidden="true"></i></a></li> */}
              <li><a href="https://www.linkedin.com/company/ocsc-supply-chain-solutions"> <i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
              </ul>
              <p class="footer_text">All rights reserved. OC Supply Chain Solutions LLC</p>
              </div>
           </div>
        </div>
     </div>


    );
}

export default Footer;