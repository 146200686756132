import React from 'react';
import Nav from '../Nav.js';
import Footer from '../Footer.js'




function WorldHunger(){

    return (
        <body>
        <Nav /><br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="container">
           <div class="row">
            <div class="col-1"></div>
              <div class="col-md-6">
              <div class=""><img style={{width:600}}src="blogimages/blog6.png" alt=""/></div>
              </div>
              <div class="col-md-4">
                 <div class="about_text_main">
                    <h1 class="blog_title">How Supply Chains Will End World Hunger</h1>
                    <h3 class="blog_author">By: Parker Hoopes</h3><b></b>
                    <span class="blog_date">7/11/2023</span><br></br>
                    <img style={{width:100}} class="ml" src="blogimages/parker.png" alt=""/>
                 </div>
              </div>
              <div class="col-1"></div>
           </div>
            <hr></hr>
           {/* This section is for showing out the blog posts */}
           <div class="row">
                <div class="col">
                    <p class="blog_text">“Leaders win through logistics. Vision,
                    sure. Strategy, yes. But when you go
                    to war, you need to have both toilet
                    paper and bullets at the right place at
                    the right time. In other words, you
                    must win through superior logistic.”
                    Tom Peters.
                    </p>
                    <p class="blog_text">In a war against world hunger one of
                    the possible solutions is creating
                    efficient supply chains. Based on my
                    research, money is not the main
                    solution to end world hunger but
                    rather efficient supply chains that can
                    help in distributing resources and
                    creating connections through many
                    different countries.
                    </p> 
                    <p class="blog_text">As figure 1 shows, the amount of
                    people in the world that are
                    malnourished is rising since hitting a
                    low in 2013. While this can be due to
                    a variety of different causes and
                    situations, the way to end hunger is
                    through proper funding and efficient
                    supply chains. While this may not
                    solve the problem for everyone, it
                    will help millions of people.</p>
                    <img style={{width:900}} class="ml-5" src="blogimages/blog5.png" alt=""/>
                    <p class="blog_text">Mortson enterprises explains that
                    food waste happens in almost all
                    stages of the supply chain. Some of
                    these wastes aren’t by fault of
                    anyone, for example natural
                    disasters, but there is something that
                    can be improved on in every stage to
                    allow more food to be distributed by
                    the end of the process. The following
                    paragraphs explain how food is
                    wasted in each process and some
                    potential solutions as explained by
                    Mortson enterprises.</p> 
                     <p class="blog_text">Harvest is the first stage in the food
                    supply chain. The first problem is
                    market conditions during harvest
                    time. The price of the product may
                    not be enough to pay for labor and
                    other associated cost. To mitigate
                    this there should be incentives to
                    complete the harvest. Another
                    problem is standards of buyers.
                    Products not meeting standards are
                    often left in the field. Secondary
                    markets should be created that allow
                    these products to still be sold with
                    only cosmetic flaws. The next stage is
                    food processing.</p>
                    <p class="blog_text">Food processing includes deskinning
                    of fruits and vegetables; removals of
                    fats, bones, and other undesired
                    parts. Inefficiencies in this process
                    also cause additional food to be lost
                    such as lack of refrigeration or proper
                    processing. To combat this problem,
                    secondary uses of waste can be
                    found and updated process and
                    infrastructure to improve food
                    processing.</p>
                    <p class="blog_text">After the processing is complete
                    distribution is the next step in the
                    supply chain. Problems in distribution
                    include improper handling of foods
                    that are time sensitive, inconsistent
                    refrigeration from trucks and
                    transport, rejected shipments leading
                    to shorter time to reach shelves and
                    people. To fix these problems more
                    training is being given to those that
                    handle shipments of food. The
                    industry is also looking into online
                    solutions to help with donating or
                    resale of rejected shipments. These

                    Figure 2 shows the way that food is spoiled during the supply
                    chain as explained by Mortson Enterprices.

                    solutions will help to make
                    distribution more effective.
                    Next on our supply chain journey is
                    the retail side of things. One of the
                    main problems on the retail side is
                    the improper labeling on foods and
                    their sale date. When the date is
                    passed the product is removed from
                    the shelf and discarded. Often these
                    food products don’t need to be
                    discarded but could be donated or
                    sold at a discounted price.
                    Another problem is not allowing
                    stores to buy what they need. This
                    leads to stores having more food
                    than they can sell resulting in more
                    wasted items. Overall to solve retail
                    problems food that stores can’t sell
                    should be redistributed to local food
                    banks or shelters that can use them
                    immediately.</p>
                    <img style={{width:600}} class="ml-5" src="blogimages/blog7.png" alt=""/>
                    <p class="blog_text">The final step in the supply chain
                    process is the consumer or in other
                    words- us! If you have ever let food
                    sit in your fridge for a month just to
                    throw it out, you are part of the
                    problem. Impulse and bulk buying
                    lead to food spoilage and is often a
                    problem for people buying for
                    themselves or just one other. Similar
                    to the retail side, consumers are
                    often confused over date labels

                    which may cause them to throw out
                    food prematurely. The main solution
                    to these problems would be to
                    inform consumers on what they can
                    do to conserve food. We can all do
                    our part in food conservation and
                    use.</p>
                    <p class="blog_text">One of the main leaders in ending
                    world hunger is the World Food
                    Programme (WFP). Just last year WFP
                    assisted 115.5 million people in 84
                    countries. The WFP has established a
                    supply chain that helps in its efforts
                    to end hunger. This includes having
                    5,600 trucks, 30 ships, and 100 planes
                    moving on a given day to help get
                    food where it needs to be.</p>
                    <p class="blog_text">The Church of Jesus Christ of Latter-
                    Day Saints and their humanitarian
                    branch of LDS Charities has also
                    paired up with WFP to work towards
                    ending hunger. Their projects
                    together have included many things.
                    The first is emergency response after
                    natural disasters.</p>
                    <img style={{width:900}} class="ml-5" src="blogimages/blog8.jpeg" alt=""/>
                    <p class="blog_text">The Church has also paired up with
                    WFP to participate in a program
                    called, Home Grown School Meals.
                    This program is built to help in
                    providing typical school meals,
                    supports small farms, the local
                    economy, and creates jobs for
                    training.</p>
                    <p class="blog_text">Organizations like WFP and the
                    Church of Jesus Christ are just a few
                    examples of partnerships between
                    organizations working together to
                    end world hunger.</p>
                    <p class="blog_text">The time for us to act is now. Hunger
                    is something that affects 1/9 people
                    worldwide. From farmers to
                    distributors to consumers each of us
                    has a role to play in the supply chain
                    to end world hunger. Now is when
                    companies and governments need to
                    set differences aside to help people
                    meet one of their basic needs and
                    having efficient supply chains will be
                    the means to do it.</p>
                </div>
           </div>
           <br></br>
           <hr></hr><br></br>
           {/* End Section */}
           
        </div>
        <Footer />
    </body>

    );
}

export default WorldHunger;