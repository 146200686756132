import React from 'react';
import Footer from './Footer.js'
import Nav from './Nav.js';
import Contact from './Contact.js';




function ContactUs(){

    return (
        <body>
        <Nav / >
        <Contact / >
        <Footer />
        </body>


    );
}

export default ContactUs;