import React from 'react';
import Nav from '../Nav.js';
import Footer from '../Footer.js'





function UtahSmallBusiness(){

    return (
        <body>
        <Nav /><br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="container">
            <div class="row">
                <div class="col-7">

                    <p class="blog_text">
                    At OC Supply Chain Solutions, we understand the unique challenges and opportunities that come with running a small business in the 
                    dynamic landscape of Utah. Our team of experienced consultants is passionate about helping local businesses thrive by providing tailored 
                    strategies and expert guidance.
                    </p>

                    <p class="blog_text">
                    Why choose us? Here's what sets us apart:
                    </p>

                    <p class="blog_text">1. Local Expertise: Our consultants have an in-depth understanding 
                    of the Utah business environment. We stay up-to-date with the latest market trends, 
                    regulatory changes, and consumer preferences specific to the state, ensuring you receive advice that is 
                    relevant and effective.</p>
                    <p class="blog_text">2. Customized Solutions: We believe that every business is unique, and we approach each client with a fresh perspective. 
                    Our team takes the time to assess your specific goals, challenges, and opportunities, creating customized strategies that 
                    align with your vision and resources.</p>
                    <p class="blog_text">3. Holistic Approach: We take a comprehensive approach to business growth, considering all 
                    aspects of your operations. From marketing and sales to operations, finance, and technology, we provide integrated 
                    solutions that address your business's core needs, leading to sustainable growth.</p>
                    <p class="blog_text">4. Collaborative Partnership: We believe in building long-term relationships with our clients. 
                    We work hand in hand with you, fostering open communication and collaboration throughout the consulting process. 
                    Your success is our success, and we are dedicated to supporting you every step of the way.</p>
                    <p class="blog_text">Whether you're a startup seeking guidance on market entry strategies, an established business aiming to expand your customer 
                    base, or an entrepreneur looking to optimize your operations, Utah Small Business Consulting is here to 
                    help you achieve your goals.</p>
                    <p class="blog_text">Take the first step towards unlocking your business's full potential. <a href="contact">Contact us</a> today to schedule a consultation. </p>
                </div>
                <div class="col">
                    <img style={{width:1100}} src="images/smallb.webp" alt=""/>
                </div>
            </div>
        </div><br></br><br></br>
        <Footer />
    </body>

    );
}

export default UtahSmallBusiness;