import React from 'react';
import background from "./Images/jeff_red.jpeg";




function Header(){

    return (
    
        <div class="header_section" style={{ 
         backgroundImage: `url(${background})` }}>
        <div class="banner_section layout_padding">
           <div class="container-fluid">
              <div id="banner_slider" class="carousel slide" data-ride="carousel">
                 <div class="carousel-inner">
                    <div class="carousel-item active" >
                       <div class="row">
                          <div class="col-md-6">
                             <div class="banner_taital_main">
                                <h1 class="banner_taital">Supply Chain Solutions</h1>
                                <p class="banner_text">Global markets are now the normal. Global business have to perfect and manage their international supplies. Let us help.</p>
                                <div class="readmore_btn active"><a href="/">Read More</a></div>
                                <div class="started_bt"><a href="contact">Contact Us</a></div>
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="banner_img"><img src="" alt=""/></div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item">
                       <div class="row">
                          <div class="col-md-6">
                             <div class="banner_taital_main">
                                <h1 class="banner_taital">OCSC Contributors</h1>
                                <p class="banner_text">Find news, blog posts, and articles from our contributors on everything from supply chain, small business, and ecommerce trends.</p>
                                <div class="readmore_btn active"><a href="blog">Read More</a></div>
                                {/* <div class="started_bt"><a href="/">Contact Us</a></div> */}
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="banner_img"><img src="" alt=""/></div>
                          </div>
                       </div>
                    </div>
                    {/* <div class="carousel-item">
                       <div class="row">
                          <div class="col-md-6">
                             <div class="banner_taital_main">
                                <h1 class="banner_taital">We Provide Law Practice</h1>
                                <p class="banner_text">Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem</p>
                                <div class="readmore_btn active"><a href="/">Read More</a></div>
                                <div class="started_bt"><a href="/">Contact Us</a></div>
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="banner_img"><img src="images/banner-img.png" alt=""/></div>
                          </div>
                       </div>
                    </div> */}
                 </div>
                 <a class="carousel-control-prev" href="#banner_slider" role="button" data-slide="prev">
                 <i class="fa fa-arrow-left"></i>
                 </a>
                 <a class="carousel-control-next" href="#banner_slider" role="button" data-slide="next">
                 <i class="fa fa-arrow-right"></i>
                 </a>
              </div>
           </div>
        </div>
     </div>


    );
}

export default Header;