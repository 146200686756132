import React from 'react';




function BlogList(){

    return (
        <div class="contact_section layout_padding">
        <div class="container">
           <div class="row">
              <div class="col-lg-7">
                 <div class="container_img"><img src="images/container.gif" alt=""/></div>
              </div>
              <div class="col-md-5">
                 <div class="about_text_main">
                    <h1 class="blog_list_title">OCSC Contributors</h1>
                    <br></br><br></br><br></br><br></br><br></br>
                 </div>
              </div>
           </div>
            <hr></hr>
            <br></br>
           {/* This section is for listing out the blog posts */}

           <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:550}} src="blogimages/blog7.jpeg" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links" href="appdev"><u>What is a platform application developer?</u></a><br></br>
                    <a class="blog_sublinks" href="appdev">Author: Ryan Pinkney</a><br></br>
                    <span class="blog_date">7/30/2023</span>
                </div>
           </div><br></br>
           <hr></hr>


           <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:550}} src="blogimages/blog6.png" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links" href="worldhunger"><u>How Supply Chains Will End World Hunger</u></a><br></br>
                    <a class="blog_sublinks" href="worldhunger">Author: Parker Hoopes</a><br></br>
                    <span class="blog_date">7/11/2023</span>
                </div>
           </div><br></br>
           <hr></hr>

           <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:350}} src="blogimages/blog2.jpeg" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links" href="fragilitytoresilience"><u>From Fragility to Resilience: Unlocking the Potential of Infrastructure in the Supply Chain</u></a><br></br>
                    <a class="blog_sublinks" href="fragilitytoresilience">Author: Nicholas Riehle</a><br></br>
                    <span class="blog_date">7/7/2023</span>
                </div>
           </div><br></br>
           <hr></hr>

           <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:250}} src="blogimages/blog1.jpg" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links" href="extremeownership"><u>Book Review: Extreme Ownership</u></a><br></br>
                    <a class="blog_sublinks" href="extremeownership">Author: Ryan Pinkney</a><br></br>
                    <span class="blog_date">5/24/2023</span>
                </div>
           </div><br></br>
           <hr></hr>

           {/* <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:250}} src="blogimages/blog1.jpg" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links" href="extremeownership"><u>From Fragility to Resilience: Unlocking the Potential of Infrastructure in the Supply Chain</u></a><br></br>
                    <a class="blog_sublinks" href="extremeownership">Author: Nicholas</a><br></br>
                    <span class="blog_date">7/7/2023</span>
                </div>
           </div><br></br>
           <hr></hr> */}


           {/* End Section */}

        </div>
     </div>


    );
}

export default BlogList;