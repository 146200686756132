import React from 'react';




function Team(){

    return (
     <div class="container">
        <br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="row">
            <div class="col-5"></div>
            <div class="col">
                <h1 class="team-title">Our Team</h1>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="row">
            <div class="col">
                <img class="team-image" src="images/pinkney-min-min copy.jpg" alt="" /><br></br>
                <h2>Ryan Pinkney</h2>
                <h3>Co-Founder | BYU Master's of Information System Management</h3>
            </div>
            <div class="col">
                <img class="team-image" src="images/jef.jpeg" alt="" />
                <h2>Jeffrey Eidem</h2>
                <h3>Co-Founder | BYU Bachelors's of Supply Chain Management</h3>
            </div>
            <div class="col">
                <img class="team-image" src="images/parker.png" alt="" />
                <h2>Parker Hoopes</h2>
                <h3>Co-Founder | BYU Bachelors's of Supply Chain Management</h3>
            </div>
        </div>
     </div>


    );
}

export default Team;