import React from 'react';
import Nav from '../Nav.js';
import Footer from '../Footer.js'




function AppDev(){

    return (
        <body>
        <Nav /><br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="container">
           <div class="row">
            <div class="col-1"></div>
              <div class="col-md-6">
              <div class=""><img style={{width:850}}src="blogimages/blog7.jpeg" alt=""/></div>
              </div>
              <div class="col-md-4">
                 <div class="about_text_main">
                    <h1 class="blog_title">What is a platform application developer?</h1>
                    <h3 class="blog_author">By: Ryan Pinkney</h3><b></b>
                    <span class="blog_date">7/30/2023</span>
                 </div>
              </div>
              <div class="col-1"></div>
           </div>
            <hr></hr>
           {/* This section is for showing out the blog posts */}
           <div class="row">
                <div class="col">
                    <p class="blog_text">With the continual rise of cloud computing, we see platform as a service (PaaS) companies growing larger and larger in 
                    popularity and size. Companies like these often focus on business to business (B2B) strategies and allow companies to reduce its costs on 
                    IT infrastructure and development. Though there are many, Salesforce and ServiceNow are some of the big players in this space. Salesforce, 
                    a company that offers a wide variety of customer relationship management (CRM) related services,  achieved earnings of $7.72 billion in Q2 
                    of 2023. ServiceNow, an IT management platform, reached earnings of $2 billion in Q2 of 2023. It is safe to assume these companies are not 
                    going away. 
                    </p>
                    <p class="blog_text">With the expansion of the PaaS space comes a huge number of new career opportunities outside of the traditional 
                    software and web application career tracks. Platform application developer is one of these new tracks growing in popularity and demand. 
                    Platform application developers specialize in the design, development, and implementation of applications deployed on platforms like 
                    Salesforce and ServiceNow. 
                    </p> 
                    <p class="blog_text">Though the discipline is similar to traditional software development, there are some important differences. Platform 
                    application developers do not have to have the same depth of understand of IT infrastructure. One of the major cost and time benefits of 
                    PaaS is that your developers do not have to worry about infrastructure. </p>
                    <p class="blog_text">Platform application developers have to be experts of their platform. For example, Salesforce developers and 
                    administrators have to gain a superior knowledge of the platform, products, and features. That process takes time and effort. Platform 
                    application developers also have to be well-versed in UI/UX design as PaaS gives developers more time to work on the front end.</p> 
                     <p class="blog_text">Platform application development is an incredible opportunity. Due to the specialty of the discipline, platform 
                     application development can also be extremely lucrative and have higher base salaries than traditional development. For example, 
                     according to ZipRecruiter, average salary for Salesforce professional entry level position land around $95k-$100k while the average 
                     salaries for software developers is around $79K-$90k. For companies that are heavily invested in Salesforce or ServiceNow, salaries 
                     also grow exponentially. </p>
                     <p class="blog_text">For those interested in platform application development, here are some important items to keep in mind:</p>
                     <h3>1. Know the major platforms</h3>
                     <p class="blog_text">Though it is impossible to know them all, it is important to start to learn and master the major platforms. I 
                     have spent a significant amount of time learning Salesforce and ServiceNow since they are some of the biggest and most widely used, 
                     however there are many others.</p>
                     <h3>2. Develop project management skills and abilities</h3>
                     <p class="blog_text">Though project management skills are good for any developer, there become especially 
                     important as a platform application developer. Since many PaaS platforms are new, stakeholder and users are 
                     less familiar with their offerings and features. Platform application developers have to be more involved in the 
                     stakeholder management, timeline scheduling, and discovery process.</p>
                     <h3>3. Understand system integration</h3>
                     <p class="blog_text">Most platforms are perfectly designed for integrations. I consider this one of the major advantages. 
                     As opposed to traditional applications that require developers to build all the APIs, Salesforce and ServiceNow come with 
                     prebuilt APIs and hooks that can be leveraged to connect all your business applications. In the case of Salesforce, Salesforce 
                     has an entire universe of applications like Slack and Tableau that all have easy integrations and cross-application features.</p>
                     <h3>4. Master UI/UX Design</h3>
                     <p class="blog_text">Since platform application developers can live on the infrastructure of the platform, more time can be spent 
                     on UI/UX design. </p>
                     <h3>5. Find your niche</h3>
                     <p class="blog_text">The more you specialize, the higher salary you can make. As more and more big companies move its business 
                     process to Salesforce and ServiceNow, there will be increased demand for platform application developers and system administrators 
                     to maintain these enterprise systems.</p>
                     <p class="blog_text">Platform application development is an exciting career opportunity for traditional developers. Salesforce 
                     and ServiceNow are the key platforms to learn about, and developers should always keep up with the latest technologies and 
                     innovations in the space. </p>

                </div>
           </div>
           <br></br>
           <hr></hr><br></br>
           {/* End Section */}
           
        </div>
        <Footer />
    </body>

    );
}

export default AppDev;