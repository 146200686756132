import Home from './Components/Home';
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPageTop from './Components/AboutPageTop.js';
import ContactUs from './Components/ContactUs.js';
import BlogTop from './Components/BlogTop.js';
import ExtremeOwnership from './Components/BlogPosts/ExtremeOwnership';
import UtahSmallBusiness from './Components/Subcomponent/UtahSmallBussiness';
import Ecommerce from './Components/Subcomponent/ecommerce';
import SmallRetailer from './Components/Subcomponent/smallretailer';
import FragailityToResilience from './Components/BlogPosts/FragilityToResilience';
import WorldHunger from './Components/BlogPosts/WorldHunger';
import AppDev from './Components/BlogPosts/AppDev';
import LanesPrivacy from "./Components/LanesPrivacy.js";

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<AboutPageTop />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="blog" element={<BlogTop />} />
      <Route path="utahsmallbusiness" element={<UtahSmallBusiness />} />
      <Route path="ecommerce" element={<Ecommerce />} />
      <Route path="smallretailer" element={<SmallRetailer />} />
      <Route path="lanes_privacy" element={<LanesPrivacy />} />

      {/* Routes for articles */}
      <Route path="extremeownership" element={<ExtremeOwnership />} />
      <Route path="fragilitytoresilience" element={<FragailityToResilience />} />
      <Route path="worldhunger" element={<WorldHunger />} />
      <Route path="appdev" element={<AppDev />} />

      {/* End Routes for articles */}

    </Routes></Router>
  );
}

export default App;