import React from 'react';




function About(){

    return (
        <div class="about_section layout_padding">
        <div class="container">
           <div class="row">
              <div class="col-md-5">
                 <div class="about_img"><img src="images/about-img.png" alt=""/></div>
              </div>
              <div class="col-md-6">
                 <div class="about_text_main">
                    <h1 class="about_taital">About Us</h1>
                    <p class="about_text">OC Supply Chaing Solutions is helping small businesses inside and outside Utah making big changes that are disrupting their markets.</p>
                    <div class="readmore_bt"><a href="about">Read More</a></div>
                 </div>
              </div>
           </div>
        </div>
     </div>


    );
}

export default About;