import React from 'react';
import Nav from '../Nav.js';
import Footer from '../Footer.js'




function FragilityToResilience(){

    return (
        <body>
        <Nav /><br></br><br></br><br></br><br></br><br></br><br></br>
        <div class="container">
           <div class="row">
            {/* <div class="col"></div> */}
              <div class="col-md-6">
              <div class=""><img style={{width:500}}src="blogimages/blog2.jpeg" alt=""/></div>
              </div>
              <div class="col-md-6">
                 <div class="about_text_main">
                    <h1 class="blog_title">From Fragility to Resilience: Unlocking the Potential of Infrastructure in the Supply Chain</h1>
                    <h3 class="blog_author">By: Nicholas Riehle</h3><b></b>
                    <span class="blog_date">7/7/2023</span>
                 </div>
              </div>
              <div class="col-1"></div>
           </div>
            <hr></hr>
           {/* This section is for showing out the blog posts */}
           <div class="row">
                <div class="col">
                    <p class="blog_text">Corporations' success in the international supply chain is inextricably linked to the political, social, and 
                    economic realities of the global system. To ensure a resilient and efficient supply chain, governments and logistics corporations 
                    must prioritize infrastructure investment, implement innovative approaches such as form-based codes, promote environmental sustainability, 
                    and foster social equity. By understanding the interconnectedness of these factors and actively addressing them, stakeholders can navigate 
                    the challenges of the supply chain, optimize efficiency, and pave the way for sustainable success in the dynamic global marketplace.
                    </p>
                    <p class="blog_text">The success of corporations in the international supply chain is undeniably intertwined with the political, social, and 
                    economic realities of the global system of supply. Recent years have seen the disruptive impact of various factors such as war, currency 
                    shocks, pandemics, and trade disputes. For example, the six-day blockage of the Suez Canal by a container ship in March 2021 not only 
                    captured global media attention but also exposed the vulnerability of the supply chain. The widespread usage of the term "supply chain" 
                    has emerged unexpectedly. Just as an expert lighting technician in a theater should go unnoticed by the audience, customers should only 
                    be aware of the supply chain if something goes wrong.
                    </p> 
                    <img style={{width:900}} class="ml-5" src="blogimages/blog4.jpeg" alt=""/>
                    <p class="blog_text">“The stability and success of the supply chain should be a priority for both international businesses and governments. 
                    Corporations must generate profits, and governments rely on corporations' growth to boost GDP. To support their countries' interests, 
                    governments, and logistics corporations must make appropriate infrastructure investments. Urban planning emerges as a key consideration 
                    for governments in their pursuit of success in the international marketplace. </p>
                    <p class="blog_text">Depending on their country and system of government, governments employ various approaches to enable infrastructure 
                    construction that benefits the supply chain. In many Western countries, zoning and land use regulations dictate the allowable uses in 
                    specific areas. While such policies theoretically benefit the community by separating incompatible land uses, they have increased distances 
                    between destinations in the United States and Canada. Consequently, these zoning regulations have escalated local and regional transportation 
                    costs while restricting certain efficient modes. This situation leads to fragility in the freight delivery system, as companies could 
                    resolve issues affecting one delivery method by employing an alternative method.</p> 
                    <p class="blog_text">The international supply chain relies on multiple layers of physical infrastructure. It is the responsibility 
                    of governments to facilitate infrastructure construction and allow logistics companies to utilize any mode effectively. Firstly, 
                    Long-distance modes such as ocean freight, air freight, road freight, rail freight, and pipeline distribution play critical roles. 
                    Secondly, "last-mile" delivery methods are pivotal for transporting goods in urban environments. These methods include not only 
                    delivery vans and trucks but also cargo bikes, micro depots, and emerging modes like drones and collaborative delivery networks. 
                    Adequate infrastructure investment from businesses, municipalities, and countries invested in the success of these operations is 
                    necessary for the operation of all freight delivery methods. Each mode of transportation carries advantages and disadvantages 
                    beyond mere cost. For example, shipping hazardous materials by train can often be less expensive and safer than shipping it by truck. 
                    If governments do not invest in rail to the same degree as they do roads, however, then the time delays caused by aging rail 
                    infrastructure make it a non-option. </p>
                    <img style={{width:900}} class="ml-5" src="blogimages/blog3.jpeg" alt=""/>
                    <p class="blog_text">Multiple solutions exist to address the challenges posed by zoning regulations on the efficient operation of the 
                    supply chain. For example, the implementation of form-based codes, which consider various design factors and minimize the physical 
                    separation of functions, can enhance the efficiency of local and regional delivery in the supply chain. Form-based codes offer greater 
                    integration compared to zoning regulations and optimize infrastructure layout, thereby encouraging multi-modal delivery approaches. These 
                    codes are flexible and adaptable, enabling expedited code changes and a more responsive permitting and development process that caters to
                     the dynamic needs of companies.</p>
                    <p class="blog_text">The short-term and long-term success of companies depends in part on their commitment to environmental sustainability. 
                    Businesses can capitalize on the market's increasing recognition and rewards for environmentally sustainable practices. In the long run, 
                    significant environmental damage resulting from unsustainable transportation modes can harm infrastructure and disrupt the supply chain.
                     Governments and corporations mutually benefit from considering the environmental impact of transportation in the global supply chain. 
                     Achieving environmental sustainability entails relocating production, reducing vehicle miles traveled, and reimagining the existing 
                     global supply system. The current supply chain model heavily relies on linear, resource-intensive practices, leading to waste generation, 
                     excessive energy consumption, and environmental degradation. Although the current system has contributed to environmental decline, which 
                     poses future threats, the adaptable nature of the global supply chain suggests that environmental sustainability will shape the necessary 
                     adaptations in the coming decades. Updated infrastructure can include efficient transportation networks, renewable energy integration,
                      and green building and sustainable facilities. These and other measures are adaptations necessary to address environmental challenges 
                      in the global supply chain.</p>
                    <p class="blog_text">To solve environmental and other issues in the supply chain, many propose technological innovation as the panacea. 
                    While technology plays a significant role in supply chain management, many efficiency issues can be addressed using existing technologies. 
                    Logistics companies should invest in a diverse fleet of transportation modes to mitigate the impact of delays in specific modes. 
                    Governments can diversify national and international delivery capabilities by facilitating investment in high-speed freight rail
                     infrastructure. Despite the initial investment costs, prioritizing methods with lower long-term maintenance costs is an underutilized 
                     yet wise strategy to maintain a resilient supply chain.</p>
                    <p class="blog_text">Ultimately, infrastructure serves as the backbone of the global supply chain. Governments and corporations must
                     commit to best practices, continuous innovation, and investment in efficiency and sustainability to effectively navigate the challenges 
                     they will encounter in the future.</p>
                </div>
           </div>
           <br></br>
           <hr></hr><br></br>
           {/* End Section */}
           
        </div>
        <Footer />
    </body>

    );
}

export default FragilityToResilience;