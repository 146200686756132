import React from 'react';




function Nav(){

    return (
        <div class="nav_section">
        <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <a class="navbar-brand" href="/"><img src="images/logonew.png" alt="" /></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                            <a class="nav-link" href="/">Home</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" href="about">About</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" href="contact">Contact</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" href="blog">Blog</a>
                            </li>
                        </ul>
                        <form class="form-inline my-2 my-lg-0">
                            <div class="login_bt">
                            <ul>
                                {/* <li><a href="/"><span class="user_icon"><i class="fa fa-user" aria-hidden="true"></i></span>Login</a></li> */}
                                <li><a href="/"> <i class="fa fa-search" aria-hidden="true"></i></a></li>
                            </ul>
                            </div>
                        </form>
                    </div>
                </nav>
            </div>
        </div>


    );
}

export default Nav;