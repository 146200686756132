import React from 'react';
import Footer from './Footer.js'
import Nav from './Nav.js';
import BlogList from './BlogList.js';




function BlogTop(){

    return (
        <body>
        <Nav / >
        <BlogList / >
        <Footer />
        </body>


    );
}

export default BlogTop;