import React from 'react';




function Service(){

    return (

   
            <div class="services_section_2">
                <div class="container">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="service_box">
                            <div class="house_icon">
                                <img src="images/icon-1.png" class="image_1" alt=""/>
                                <img src="images/icon-4.png" class="image_2" alt=""/>
                            </div>
                            <h3 class="corporate_text">Utah Small Business</h3>
                            <p class="chunks_text">Utah is a thrieving economy for small businesses in both tech and eCommerce. We help businesses scale to take market share. </p>
                            <div class="readmore_button"><a href="utahsmallbusiness">Read More</a></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service_box">
                            <div class="house_icon">
                                <img src="images/icon-5.png" class="image_1" alt=""/>
                                <img src="images/icon-5.png" class="image_2" alt=""/>
                            </div>
                            <h3 class="corporate_text">E-Commerce</h3>
                            <p class="chunks_text">Business happens online. Our team of expert technologists can help your business grow from SEO to CRM consulting. </p>
                            <div class="readmore_button"><a href="ecommerce">Read More</a></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service_box">
                            <div class="house_icon">
                                <img src="images/icon-3.png" class="image_1" alt=""/>
                                <img src="images/icon-6.png" class="image_2" alt=""/>
                            </div>
                            <h3 class="corporate_text">Small Retailer</h3>
                            <p class="chunks_text">Small businesses are the heart of our economy and communities. We help these business make smart supply chain decisions and manage relationship to deliver value. </p>
                            <div class="readmore_button"><a href="smallretailer">Read More</a></div>
                        </div>
                    </div>
                    </div>
                </div><br></br><br></br><br></br><br></br>
            </div>

        


    );
}

export default Service;