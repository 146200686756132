import React from 'react';
import Footer from './Footer.js'
import Nav from './Nav.js';
import AboutPageExtended from './AboutPageExtended.js';
import Team from './Team.js';



function AboutPageTop(){

    return (
        <body>
        <Nav / >
        <Team />
        <AboutPageExtended />
        <Footer />
        </body>


    );
}

export default AboutPageTop;