import React from 'react';




function Clients(){

    return (
        <div class="contact_section layout_padding">
        <div class="container">
           <div class="row">
              <div class="col-sm-12">
                 <h1 class="contact_taital"> - Clients - </h1>
              </div>
           </div>
           <br></br><br></br><br></br>
           <div class="contact_section_2">
              <div class="row">
                 <div class="col-md-6">
                     <img src="images/haven.png" alt=""/>
                 </div>
                 <div class="col-md-6">
                     <img src="images/caliber.jpeg" alt=""/>
                 </div>
              </div>
           </div>
        </div>
     </div>


    );
}

export default Clients;