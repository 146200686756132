import React from 'react';




function BlogHomePage(){

    return (
        <div class="about_section layout_padding">
        <div class="container">
        <div class="row">
            {/* <div class="col-3">
                <img style={{width:150}} src="images/container.gif" alt=""/>
            </div> */}
            <div class="col">
                <h1 class="blog_highlight_title">OCSC Contributors</h1>
                </div>
        </div><br></br>
        <br></br>
        <div class="row">
                <div class="col-4">
                <div class=""><img style={{width:550}} src="blogimages/blog7.jpeg" alt=""/></div>
                </div>
                <div class="col-8">
                    <a class="blog_links_home" href="appdev"><u>What is a platform application developer?</u></a><br></br>
                    <a class="blog_sublinks_home" href="appdev">Author: Ryan Pinkney</a><br></br>
                    <span class="blog_date_home">7/30/2023</span>
                </div>
           </div><br></br>
        </div>
     </div>


    );
}

export default BlogHomePage;