import React from 'react';




function Contact(){


    return (
        <div class="contact_section layout_padding">
            <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                        <div class="about_img"><img src="images/about-img.png" alt=""/></div>
                        </div>
                        <div class="col-md-6">
                            <p class="contact-title">Let us know how we can help grow your small business:</p>
                            <form name="contact" method="post">
                            <input type="hidden" name="form-name" value="contact" />
                            <p>
                                <label htmlFor="name">Name</label> <br />
                                <input type="text" id="name" name="name" required />
                            </p>
                            <p>
                                <label htmlFor="email">Email</label> <br />
                                <input type="email" id="email" name="email" required />
                            </p>

                            <p>
                                <label htmlFor="company">Your Company</label> <br />
                                <input type="text" id="company" name="company" required />
                            </p>

                            <p>
                                <label htmlFor="message">Message</label> <br />
                                <textarea id="message" name="message" required></textarea>
                            </p>
                            <p>
                                <input type="submit" value="Send" />
                            </p>
                            </form>
                            <br></br>
                        </div>
                    </div>
                </div>
     </div>


    );
}

export default Contact;