import React from 'react';
import Header from './Header.js'
import Service from './Service.js'
import About from './About.js'
import Footer from './Footer.js'
import Clients from './Clients.js'
import Nav from './Nav.js'
import SurveyForm from './SurveyForm.js';
import BlogHomePage from './BlogHomePage.js';



function Home(){

    return (
        <body>
        <Nav />
        <Header />
        <About />
        <SurveyForm />
        <Service />
        <BlogHomePage />
        <Clients />
        <Footer />
        </body>


    );
}

export default Home;