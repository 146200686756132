import React from 'react';




function AboutPageExtended(){

    return (

        <div class="layout_padding">
        <div class="container">
           <div class="row">
              <div class="col-4">
                 <div class="about_img"><img src="images/about-img.png" alt=""/></div>
              </div>
              <div class="col">
                    <h1 class="about_taital">About Us</h1>
                    <p class="blog_text">As an OC Company, OCSC is focused on creating the opportunity for success, 
                    for every client, business, and employee. OCSC is committed to providing the necessary 
                    resources and support to help everyone associated with it achieve their goals and reach their full potential.</p>
                    <p class="blog_text">OCSC provides cutting edge supply chain solutions to businesses of all sizes. 
                    We are constantly learning to ensure that our work is comprised of the best techniques and most current technology. 
                    We find success through the success of our clients, and aim to create long lasting relationships through our work.</p>
                    <p class="blog_text">We believe in the power of hard work and the pursuit of 
                    excellence to achieve our goals and create a better future for ourselves and others. </p>
                  <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
              </div>
           </div>
        </div>
     </div>
  


    );
}

export default AboutPageExtended;